@tailwind components;

@layer components {

    .drp-button {
        @apply rounded-xl ring-[1px] ring-transparent hover:ring-line-dark hover:text-primary-default hover:bg-higherlight p-2;
    }

    .drp-button.disabled {
        @apply opacity-50 cursor-not-allowed;
    }

    .drp-day.current-day {
        @apply font-bold text-primary-light;
    }

    .drp-day.disabled {
        @apply text-color-light opacity-60 cursor-not-allowed;
    }

    .drp-day.not-current-month {
        @apply opacity-0 pointer-events-none;
    }

    .drp-day.not-in-range {
        @apply ring-[1px] ring-transparent hover:ring-line hover:bg-higherlight focus-visible:ring-primary-default focus-visible:bg-higherlight rounded-xl;
    }

    .drp-day.range-start, .drp-day.will-be-range-start, .drp-day.range-end, .drp-day.will-be-range-end {
        @apply font-medium bg-primary-light hover:bg-primary-default text-primary-lighter dark:text-primary-dark focus-visible:bg-primary-default;
    }

    .drp-day.in-range, .drp-day.will-be-in-range {
        @apply font-medium bg-primary-light hover:bg-primary-default text-primary-lighter dark:text-primary-dark focus-visible:bg-primary-default;
    }

    .drp-day.range-start, .drp-day.will-be-range-start {
        @apply rounded-l-2xl;
    }

    .drp-day.range-end, .drp-day.will-be-range-end {
        @apply rounded-r-2xl;
    }

    /*.drp-day.range-start:nth-child(7n), .drp-day.will-be-range-start:nth-child(7n), .drp-day.in-range:nth-child(7n), .drp-day.will-be-in-range:nth-child(7n), .drp-day.in-range:nth-child(7n)::before, .drp-day.will-be-in-range:nth-child(7n)::before {*/
    /*    @apply rounded-r-xl;*/
    /*}*/

    /*.drp-day.in-range:nth-child(7n+1), .drp-day.will-be-in-range:nth-child(7n+1) {*/
    /*    @apply rounded-l-xl;*/
    /*}*/

}