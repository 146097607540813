@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Basier';
  src: url('fonts/basiercircle-regular-webfont.woff2') format('woff2'), url('fonts/basiercircle-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Basier';
  src: url('fonts/basiercircle-medium-webfont.woff2') format('woff2'), url('fonts/basiercircle-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Basier';
  src: url('fonts/basiercircle-semibold-webfont.woff2') format('woff2'), url('fonts/basiercircle-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Basier';
  src: url('fonts/basiercircle-bold-webfont.woff2') format('woff2'), url('fonts/basiercircle-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

:root {
  --primaryLighter: 242 235 252;
  --primaryLight: 136 74 211;
  --primary: 100 44 169;
  --primaryDark: 77 33 130;
  --primaryDarker: 48 20 82;

  --higherlight: #fff;
  --highlight: #fafafa;
  --lowlight: #e4e5e6;

  --color: #111827;
  --colorLight: #8e939b;
  --colorError: #cf222e;
  --line: #d6dae0;
  --lineDark: #e5e7eb;

  --raisinBlack: #231F20;

  --background: #f9fafb;
  --backgroundDark: #eff0f2;
}

.dark {
  color-scheme: dark;

  --primaryLight: 196 168 230;
  --primary: 174 133 224;
  --primaryDark: 80 62 101;
  --primaryDarker: 69 44 99;

  --higherlight: #2D2D2D;
  --highlight: #232323;
  --lowlight: #111111;

  --color: #f3f4f6;
  --colorLight: #9ca3af;
  --colorError: #ff7b72;
  --line: #505059;
  --lineDark: #27272a;

  --background: #1B1B1B;
  --backgroundDark: #131313;
}

* {
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  outline: none;
  box-sizing: border-box;
}

html,body {
  margin: 0;
  background: var(--background);
  color: var(--color);
  font-family: 'Basier', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}