@tailwind components;

@layer components {

    thead {
        @apply h-8 bg-background-default px-3 py-3 text-color-light z-40
    }

    /* Applies Floating Border around the `thead` */
    thead::after {
        @apply border-y border-line absolute w-full h-[36px] sm:h-[44px] top-0 pointer-events-none;
        content: "";
    }

    th {
        @apply font-normal sm:p-3 py-2 px-3;
    }

    tr > td > a {
        @apply flex items-center sm:px-2 px-3 py-3;
    }

    tr > td > a:focus-visible {
        @apply outline outline-2 outline-offset-2 outline-primary-default rounded-lg text-primary-default;
    }

    tr > td:first-child {
        @apply rounded-l-2xl pl-2;
    }

    tr > td:last-child {
        @apply rounded-r-2xl;
    }

    tr:hover td {
        @apply bg-background-dark;
    }
}