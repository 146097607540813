@tailwind components;

@layer components {

    .pill {
        @apply px-2 py-1 rounded-lg font-medium flex items-center;
    }

    .pill.filled.green {
        background: #d1fae5;
        color: #065f46;
    }

    .dark .pill.filled.green {
        background: #1c3320;
        color: #49b95d;
    }

    .pill.filled.yellow {
        background: #f6f1d4;
        color: #8f610d;
    }

    .dark .pill.filled.yellow {
        background: #362d17;
        color: #d3ab40;
    }

    .pill.filled.red {
        background: #fee2e2;
        color: #991b1b;
    }

    .dark .pill.filled.red {
        background: #372127;
        color: #d55c78;
    }

    .pill.filled.bare {
        background: #e4e4e7;
        color: #262626;
    }

    .dark .pill.filled.bare {
        background: #2E2E2E;
        color: #c1c7d2;
    }

    .pill.withdot > .dot {
        @apply w-2 h-2 rounded-full mr-2;
    }

    .pill.withdot.yellow {
        background: #f6f1d4;
        color: #8f610d;
    }

    .dark .pill.withdot.yellow {
        background: #362d17;
        color: #d3ab40;
    }

    .dark .pill.withdot.yellow > .dot {
        background: #d3ab40;
    }

    .pill.withdot.yellow > .dot {
        background: #8f610d;
    }
}